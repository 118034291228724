/* width */
::-webkit-scrollbar {
    width: 7px;
   }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.12); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
   background: #888; 
  }
  
