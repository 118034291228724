
.callsheetContainer{
    width: 100%;
    height: 100%;
    border-radius: 15px;
    height: 100%;
    background-color: 252525;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    background-color: #252525;

    .callsheetBody{
        width: 50%;
    }

    .callsheetList {
        filter: drop-shadow(0px 0px 1px)
    }

    .callsheetListItems div {
        display: none;
    }
    
    .callsheetListItems:hover div {
        display: flex;
    }

    .option:hover {
        background-color: #323232;
    }

}

.callsheet-input-horaire {
    height: 44px;
    width: 5vw !important;
    border-radius: 3px;
    background-color: #454545;
    box-shadow: none;
    border: none !important;
    margin-top: 0 !important;
    outline: none !important;
  }

  .pointer {cursor: pointer}

  .list-option {
    flex-flow: column;
    width: 5vw;
    background-color: #252525;
    position: absolute;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    top: 25px;
  }

.popupContainer {
    width: 30%;
    height: 60%;
}

.popupFooterContainer{
    border-top: 1px solid #323232;

    .cancelButton {
    border-radius: 20px; 
    background-color: #323232;
    color: #f5f5f5;
    }

    .validationButton {
        border-radius: 20px; 
    }
}

.scrollPanelContainer {
    height: 41vh;
}

.calltimeContainer {
    width: 100%;
}

.inputDescription {
    width: 78%;
}
  
.multiselect {
    outline: none;
}

.download-button {
    width: 40px; 
    transform: rotate(180deg);
    position: absolute;
    right: 70px; 
    top: 170px;
    cursor: pointer;
}
  