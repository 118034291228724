.p-tabview-title {
  margin-left: 5px;
}

.addFiles {
  border: 0.5px dashed white;
}

.light {
  color: #aeaeae;
}

.p-overlaypanel {
  &.contributors-panel {
    .p-overlaypanel-content {
      padding: 0 !important;
      border-radius: 10px;
    }
  }
}
.p-button.p-button-text {
  color: #adadad !important;
}

p-overlaypanel {
  border-radius: 20px;
}
