@keyframes ui-progress-spinner-color {
  0% {
    stroke: #4C4C4C;
  }
  25% {
    stroke: #aeaeae;
  }
  50% {
    stroke: #dedede;
  }
  90% {
    stroke: #e9e9e9;
  }
  100% {
    stroke: white;
  }
}
