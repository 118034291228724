.img-drawing-container {
    width: 1430px !important;
}

.img-drawing-box {
    width: 1430px !important;
}

.comment-box {
    padding: 3%;
    padding-right:0px;
    background-color: #323232;
    border-radius: 4px;
  }

  .greyColor {color: #AEAEAE}

  /*    Device = Tablets, Ipads (portrait) */

@media (min-width: 768px) and (max-width: 1024px) {
      .img-drawing-container {
       width: 100vw !important;
   }     
   }     