.custom-checkbox {
    .p-checkbox-box {
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.7);
        background: black;
    }
}
.custom-checkbox {
    .p-highlight {
        background: black !important;
    }
}
.custom-checkbox {
    .p-checkbox-box.p-highlight {
        .p-checkbox-icon.pi-check:before {
            content: "";
            position: absolute;
            right: 3px;
            bottom: 3px;
            border-radius: 50%;
            border-right: 5px solid white;
            border-bottom: 5px solid white;
            border-left: 5px solid white;
            border-top: 5px solid white;
            animation: none;
            transform-origin: unset;
            top: unset;
            left: unset;
            transform: none;
        }
    }
}
