.memberRoleSelect {
    background-color: #323232;
    border: none;
    //padding: 15px;
    border-radius: 4px;
    color: #AEAEAE;
}

.steps {
    width: 35%;
}

.p-steps-item {
    margin-left: 5em;
    margin-right: 5em;
}

.step-bar {
    border: 1px solid #323232;
    width: 5vw;
    position: absolute;
    top: 4em;
}

.stepOneContainer {
    width: 30vw;
    height: 65vh;
    background-color: #252525;
    border-radius: 8px;
    @media only screen
    and (min-device-width: 768px)
    and (max-device-width: 1024px)
    and (-webkit-min-device-pixel-ratio: 1) {
        width: 70vw;
    }

    .stepOneChild {
        width: 100%;
        background-color: #323232;
    }

    .shootingTypeSelect {
        font-family: 'Inter', sans-serif;
        background-color: #323232;
        border: none;
        padding: 15px;
        border-radius: 4px;
        color: #AEAEAE;
    }

    .dropzone {
        width: 100%;
        height: 15%;
        border: 1px dashed #F5F5F5;
        padding: 15px;

        .dropzoneTextContainer {
            width: 100%;
        }

        .list-unstyled {
            list-style: none;
        }
    }

}

.stepTwoContainer {
    width: 40vw;
    height: 65vh;
    background-color: #252525;
    border-radius: 8px;

    @media only screen
    and (min-device-width: 768px)
    and (max-device-width: 1024px)
    and (-webkit-min-device-pixel-ratio: 1) {
        width: 70vw;
    }

    .stepTwoInput {
        width: 30%;
    }
}

.footer {
    border-top: 1px solid #323232;
}

.cancelButton {
    background-color: #4C4C4C;
    border-radius: 20px;
    color: white;
}

.validationButton {
    background-color: #AEAEAE;
    border-radius: 20px;
    color: #252525;
}
