.p-accordion .p-accordion-tab{
  box-shadow: none;
}
.p-accordion-header{
  background-color: #262626 !important;
}
.p-accordion-content{
  background: #262626 !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
  background: #262626 !important;
}