  @font-face {
    font-family: 'sf-icons';
    src:  url(./../../fonts/icons/sficons.eot);
    src:  url(./../../fonts/icons/sficons.eot#iefix) format('embedded-opentype'),
      url(../../fonts/icons/sficons.ttf) format('truetype'),
      url(../../fonts/icons/sficons.woff) format('woff'),
      url(../../fonts/icons/sficons.svg#sficons) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  @import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700");
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');