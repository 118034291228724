.callsheet-input-horaire {
    height: 44px;
    width: 5vw !important;
    border-radius: 3px;
    background-color: #454545;
    box-shadow: none;
    border: none !important;
    margin-top: 0 !important;
    color: white;
    outline: none !important;
    padding: 15px !important;
  }

.pointer {cursor: pointer}

.popupContainer {
  width: 30%;
  height: 60%;
}

.popupFooterContainer{
    border-top: 1px solid #323232;

    .cancelButton {
    border-radius: 20px; 
    background-color: #323232;
    color: #f5f5f5;
    }

    .validationButton {
        border-radius: 20px; 
    }
}

.addCallsheetContainer {
  width: 45%;
}

.scrollPanelContainer {
  background-color: #252525;
  height: 41vh;
}

.calltimeContainer {
    width: 100%;
}

.inputDescription {
    width: 78%;
}

.multiselect {
  outline: none;
}

.react-datepicker__time-list li {
  margin-bottom: 1px !important;
  background-color: #454545 !important;
  color: white !important;
  font-weight: bold !important;
}

.react-datepicker__time-list li:hover {
  background-color: white !important;
  color: black !important;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .popupContainer {
    width: 70%;
    height: 60%;
  }

  .callsheet-input-horaire {
    width: 12vw !important;
  }
}