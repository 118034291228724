.file-card {
    background-image:$secondaryBgColor; //linear-gradient(179deg,rgba(40,40,40,0) 6%,#282828 96%);
    height:25vh;
    background-position:50% 20%;
    background-size: 8vh;
    background-repeat: no-repeat;

    .p-header {padding: 0px;}

    .p-card-body {
        height: 100% !important;
        padding: 0px !important;

        .p-card-container {
            height: 100%;
        }

        .p-card-content {
            padding: 0px !important;
            width: 100% !important;
            height: 100% !important;

            .p-image-container {
                height: 60%;
            }

            .p-button.p-button-text{
                color: #adadad !important;
              }
            .p-overlaypanel {
                top: unset !important;
                left: unset !important;
                margin-left: 190px !important;
            }

            .p-footer-container {
                height: 25%;

                .date-font-color {
                    color: #AEAEAE;
                }
            }
        }
    }
}

.file-card:hover{
    background-color: linear-gradient(179deg,rgba(40,40,40,0) 6%,#282828 96%);
}
