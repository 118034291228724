.product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.catalog .p-card-content{
    padding: 0;
}

.catalog .p-card-body{
    padding: 0;
}

.dropzone-file{
    padding: 40px 0px;
    border: 1px dashed white;
}

.icon-printer {
    background-color: #252525;
    border-radius: 4px;
    cursor: pointer;
}