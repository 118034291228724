.p-datatable {
    th.p-filter-column{
        .p-column-filter {
            &.p-inputtext
        {
            width:100%;
        }
    }
    }
}

