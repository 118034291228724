.p-carousel,
.select-carousel {

  &.p-carousel-indicators,
  .p-reset {
    display: none;
  }
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
}