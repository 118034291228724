.p-breadcrumb {
  &.session-breadcrumb {
    background: transparent;
    border: none;

    ul li:last-child .p-menuitem-text {
      font-weight: bold;
    }
  }
}
.p-card {
  font-size: 0.75rem;

  &.session-image-card,
  &.session-selected-image-card,
  &.session-mini-image-card,
  &.session-mini-selected-image-card {
    border-radius: 0px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 15px;
    .p-card-header {
      padding-bottom: 10px;
    }
    .p-card-body {
      padding: 0px;
      .p-card-content {
        padding: 0px;
        overflow: hidden;
        height: 185px;
      }
    }

    .p-card-footer {
      height: auto;
    }
  }

  &.session-selected-image-card,
  &.session-mini-selected-image-card {
    border: 1px solid $dividerColor;
  }
  &.session-mini-image-card,
  &.session-mini-selected-image-card {
    .p-card-body {
      .p-card-content {
        height: 155px;
      }
    }
  }
}

.label-color {
  width: 16px;
  height: 8px;
  border-radius: 20px;
}

.p-chip.custom-chip {
  background: $textSecondaryColor;
  color: $bodyBgColor;
}

.image-placeholder {
  border: 2px dashed $textSecondaryColor;
  width: 100%;
  max-width: 100%;
  height: 300px;
  max-height: 100%;
}

.tree-scrollPanel {
  height: 85vh;
}

.dropzone {
  width: 30vw;
  height: 15vh;
  border: 1px dashed #f5f5f5;
  padding: 15px;

  .dropzoneTextContainer {
    width: 100%;
  }

  .list-unstyled {
    list-style: none;
  }

  .cancelButton {
    background-color: #4c4c4c;
    border-radius: 20px;
    color: white;
  }

  .validationButton {
    background-color: #aeaeae;
    border-radius: 20px;
    color: #252525;
  }
}
.p-progress-spinner {
  width: 30vw;
  height: 10vh;
}

.small-progress-spinner {
  width: 20vw;
  height: 15vh;
}

.zoom-panel {
  background-color: #181818;
  border-radius: 5px;
  bottom: 30vh;
  right: 22vw;
  position: absolute;
  z-index: 5;
}

.zoom-minus {
  width: 22px;
  height: 22px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #252525;
  text-align: center;
}

.zoompercent {
  display: inline-block;
  margin: 0px 10px;
  min-width: 40px;
  text-align: center;
}

.zoom-plus {
  width: 22px;
  height: 22px;
  border-radius: 5px;
  background-color: #252525;
  cursor: pointer;
  text-align: center;
}

.zoom-minus:before {
  content: "-";
  font-size: 20px;
  margin: auto;
  line-height: 20px;
  transform: translate(-50%);
}

.zoom-plus:before {
  content: "+";
  font-size: 20px;
  margin: auto;
  line-height: 22px;
  transform: translate(-50%);
}
