.project-card {
    //background-image:linear-gradient(179deg,rgba(40,40,40,0) 6%,#282828 96%);
    height:285px;
    width: 200px;
    border-radius: 10px;
    cursor: pointer;
    .p-card-body{
        width: 100%;
    }

    .p-card-content {
        padding:0;
    }
    .project-title {
        color: $textColor;
        font-size: $fontSize;
    }
    .project-date, .project-shoot, .project-shoot-value {
        color: $textSecondaryColor;
        font-size: 0.9rem;
        line-height: 1.5rem;
    }
    .project-shoot-warning{
        width: 85%;
    }
    .project-shoot-value {
        color: $textSecondaryColor;
    }
}

.project-card:hover{
    //background-image:linear-gradient(179deg,rgba(0,43,169,0) 6%,#002ba9 96%);
    filter: brightness(70%);
}

.default-card-img-container {height: 180px;}
.default-card-img {max-height: 70%;}

