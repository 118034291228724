/* General */
$fontSize:14px;
$fontWeight:400;
$regularFontWeight:500;
$bodyBgColor:#181818;
$secondaryBgColor:#252525;
$thirdBgColor:#323232;
$textColor:#ffffff;
$textSecondaryColor:#B2B2B2;
$borderRadius:7px;
$dividerColor:#0A34B6;
$transitionDuration:.2s;
$maskBgColor:#424242;
$focusShadowColor:#8dcdff;
$inputBgColor:#454545;
$btnTextColor:#202020;
$btnBgColor:#ffffff;
$cardBGColor:#2d2d2d;
$addBGColor: #5A71CF;
$valBGColor: #00C492;
