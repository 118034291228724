.p-timeline 
{
    .p-timeline-event-opposite
    {
        flex:none
    }
    .p-timeline-event-content {
        color:$textColor;
        font-weight:$fontWeight;
        font-size:13px; 
        line-height: 2.0rem;
    }
    .p-timeline-event-connector {
        background-color:#494949; 
    }
    &.p-timeline-vertical .p-timeline-event-connector {
        width:1px
    }
}

