.p-rating{
    .p-rating-icon.pi-star {
        color:#AEAEAE;
        font-size: 0.75rem;
    }
    .p-rating-icon{
        color:#AEAEAE ;
        font-size: 0.75rem;
    }  
}
.p-rating:not(.p-disabled):not(.p-readonly) {
    .p-rating-icon:hover{
        color:#fff ;

    }
}