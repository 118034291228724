.p-button,
.p-button:enabled:hover,
.p-button:enabled:active,
.p-button:enabled:focus {
  font-family:'Inter', sans-serif ;
  background-color: $btnBgColor;
  color: $btnTextColor;
  &.p-button-sm {
    font-size: 0.75rem;
    padding: 0.4rem 0.4rem;
  }
  &.p-button-link {
    background-color: transparent;
    color: $textColor;
  }
}
.p-button-icon-only {
  background-color: $secondaryBgColor;
  color: $textColor;
}

.p-button-icon-only:hover,
.p-button-icon-only:active,
.p-button-icon-only:focus {
  background-color: $secondaryBgColor !important;
  color: $textColor !important;
  text-decoration: underline;
}

.p-button {
  &.card-button,
  &.card-button:enabled:focus,
  &.card-button:enabled:active
 {
    border: solid 1px $textSecondaryColor;
    background: transparent;
    color: $textSecondaryColor;
  }

  &.card-button:enabled:hover
 {
  border: solid 1px $textSecondaryColor;
    background: $textSecondaryColor;
    color: $bodyBgColor;
  }

  &.card-button-active {
    border: solid 1px $textSecondaryColor;
    background: $textSecondaryColor;
    color: $bodyBgColor;
  }

  &.card-button-add-active {
    background: $addBGColor;
    color: $bodyBgColor;
  }

  &.card-button-val-active {
    background: $valBGColor;
    color: $bodyBgColor;
  }

  &.session-button,
  &.session-button:enabled:focus,
  &.session-button:enabled:active{
    .p-button-label{
      flex:none;
    }
  }
  &.session-button:enabled:hover{
    background-color: #323232;
  }
}
.p-button-sm {
  font-size: 0.75rem;
  padding: 0.4rem 0.4rem;
}
