.p-dropdown
{
  background: $inputBgColor;

  &.color-dropdown{
    border: none;

    .p-dropdown-trigger{
      display: none;
    }

    .p-dropdown-panel{
      padding: 0;
    }

    .p-dropdown-label{
      padding: 7px;
      min-width: 30px;
    }

    .p-dropdown-item{
      padding: 25px !important;
      display: flex;
      justify-content: center;
    }

  }

}

.color-panel{


  &.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
    padding: .5rem 1rem ;
  }
}