* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: $fontSize;
}

body {
    font-family: 'Inter', sans-serif;
    font-size: $fontSize;
    font-weight: $fontWeight;
    color: $textColor;
    background-color: #000;
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a, a.link{
    text-decoration: none;
    color: $textColor;
    font-weight: $regularFontWeight;
}

.flex-container {
    max-width: 1920px;
    height:100vh;
    margin: 0 auto;	
    background-color: $bodyBgColor;  
}

.p-component {
       font-size: $fontSize;
    color: $textColor;
    
}
.p-field {
    margin-bottom: 2.5rem;
}
.p-inputtext {
    background-color: $inputBgColor;
    border:none;
}
.p-inputtext:enabled:focus {
    box-shadow:none;
}
.p-float-label input:focus ~ label, 
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label input.p-filled ~ label {
    background-color: transparent;
    color: $textSecondaryColor; 
    margin-top:-10px;
}

.sf-scrollpanel {
    width: 100%;
    height:calc(100vh - 90px);
    overflow-y:hidden;
}

section.sf-dropzone {
    border :1px dashed hsla(0,0%,100%,.5);
    height: 70px;
    align-items: center;
    justify-content: center;
    display:flex;
    width: 100%;
}

.primary-color {
    color:$textColor ;
}
.secondary-color {
    color:$textSecondaryColor ;
}
.primary-bg-color {
    background-color:$bodyBgColor ;
}

.secondary-bg-color {
    background-color:$secondaryBgColor ;
}
.third-bg-color {
    background-color:$thirdBgColor ;
}
.subtitle {
    color: $textSecondaryColor; 
}