.radio-button{
    position: absolute; 
    z-index: 2;
    top: 6px; 
    left: 8px; 
    background: none !important;
    color: rgba(255, 255, 255, 1) !important;
    border: none !important;
    opacity: 1;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left, .p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: rgba(255, 255, 255, 1) !important;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: rgba(255, 255, 255, 1) !important;
}

.img{
    width: 100%;
    height: auto; 
    border-radius: 10px;
    object-fit: cover;
    max-height: 200px; 
    max-width: 200px; 
    cursor: pointer;
}

.img:hover{
    filter: grayscale(80%) saturate(50%);
}
