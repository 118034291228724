.p-menubar {
  background-color: transparent;
  border: none
}

.p-menu-list {
  margin-left: auto;
  margin-right: auto;

}

.p-menuitem-text {
  font-weight: $fontWeight;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background-color: transparent;
}


.notification-header:hover .header-dropdown {
  transition: all .5s ease 0s;
  opacity: 1;
  visibility: visible;
}

.header-right-menu {
  .p-submenu-icon {
    display: none
  }
;

  .p-submenu-list {
    z-index: 5
  }
}

.p-menubar-root-list {
  width: 100px;
}

.p-menubar-root-list {
  width: 100px;
}

@media screen and (max-width: 960px) {
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    width: 80px;
  }
}
