[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sf-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size:1.0rem;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-2x {
  font-size:1.5rem;
}
.icon-3x {
  font-size:2.0rem;
}
.icon-4x {
  font-size:2.5rem;
}

.icon-5x {
  font-size:3rem;
}

.icon-Action_Look .path1:before {
  content: "\e955";
  color: rgb(174, 174, 174);
}
.icon-Action_Look .path2:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(37, 247, 106);
}
.icon-Action_AssocierRef:before {
  content: "\e95c";
  color: #e9e9e9;
}
.icon-Action_Export:before {
  content: "\e904";
  color: #e9e9e9;
}
.icon-Media_Toolbox_Selection:before {
  content: "\e95b";
  color: #fff;
}
.icon-Session_Download:before {
  content: "\e951";
  color: #e9e9e9;
}

.svgIcon-Barcode:before{
  content: "   ";
  background-image: url("../../img/scan.svg");
  background-size: cover;
  display: inline-block;
  width: 15px;
  height: 15px;
  padding-left: 5px;
  color: #e9e9e9;
}

.icon-Session_Delete:before {
  content: "\e950";
  color: #e9e9e9;
}
.icon-Session_Download:before {
  content: "\e951";
  color: #e9e9e9;
}
.icon-Session_Historique:before {
  content: "\e952";
  color: #e9e9e9;
}
.icon-Session_Liens:before {
  content: "\e953";
  color: #e9e9e9;
}
.icon-Session_Retouche:before {
  content: "\e954";
  color: #e9e9e9;
}
// .icon-Session_Select1:before {
//   content: "\e955";
//   color: #e9e9e9;
// }
.icon-Session_Synchronize:before {
  content: "\e956";
  color: #e9e9e9;
}
.icon-Session_Upload:before {
  content: "\e957";
  color: #e9e9e9;
}
.icon-Session_Valider:before {
  content: "\e958";
  color: #e9e9e9;
}
.icon-Action:before {
  content: "\e900";
  color: #e9e9e9;
}
.icon-Action_:before {
  content: "\e901";
  color: #e9e9e9;
}
.icon-Action_Comment:before {
  content: "\e902";
  color: #e9e9e9;
}
.icon-Action_Delete:before {
  content: "\e903";
  color: #e9e9e9;
}
.icon-Action_Download:before {
  content: "\e904";
  color: #e9e9e9;
}
.icon-Action_Edit:before {
  content: "\e905";
  color: #e9e9e9;
}
.icon-Action_Import:before {
  content: "\e906";
  color: #e9e9e9;
}
.icon-Action_NewFile:before {
  content: "\e907";
  color: #e9e9e9;
}
.icon-Action_Open:before {
  content: "\e908";
  color: #e9e9e9;
}
.icon-Action_Poll:before {
  content: "\e909";
  color: #e9e9e9;
}
.icon-Action_Reply:before {
  content: "\e90a";
  color: #e9e9e9;
}
.icon-Action_Settings:before {
  content: "\e90b";
  color: #e9e9e9;
}
.icon-Add:before {
  content: "\e90c";
  color: #e9e9e9;
}
.icon-Arrow_Back:before {
  content: "\e90d";
  color: #e9e9e9;
}
.icon-Arrow_Last:before {
  content: "\e90e";
  color: #fff;
}
.icon-Arrow_Open:before {
  content: "\e90f";
  color: #e9e9e9;
}
.icon-Checkbox_1Empty:before {
  content: "\e910";
  color: #f5f5f5;
}
.icon-Checkbox_On:before {
  content: "\e911";
  color: #25f76a;
}
.icon-Close:before {
  content: "\e912";
  color: #e9e9e9;
}
.icon-Done:before {
  content: "\e913";
  color: #25f76a;
}
.icon-Drag:before {
  content: "\e914";
  color: #dedede;
}
.icon-File_Folder:before {
  content: "\e915";
  color: #dedede;
}
.icon-File_Pdf:before {
  content: "\e916";
  color: #d3d3d3;
}
.icon-File_Xls:before {
  content: "\e917";
  color: #d3d3d3;
}
.icon-FileBar_Expand:before {
  content: "\e918";
  color: #f5f5f5;
}
.icon-FileBar_Print:before {
  content: "\e919";
  color: #f5f5f5;
}
.icon-FileBar_Sidebar:before {
  content: "\e91a";
  color: #f5f5f5;
}
.icon-Flag_1Empty:before {
  content: "\e91b";
  color: #f5f5f5;
}
.icon-Flag_2Active:before {
  content: "\e91c";
  color: #ff2905;
}
.icon-Info:before {
  content: "\e91d";
  color: #f5f5f5;
}
.icon-Link:before {
  content: "\e91e";
  color: #aeaeae;
}
.icon-Media_Comment:before {
  content: "\e91f";
  color: #fff;
}
.icon-Media_History:before {
  content: "\e920";
  color: #e9e9e9;
}
.icon-Media_Nav_Comment:before {
  content: "\e921";
  color: #aeaeae;
}
.icon-Media_Nav_History:before {
  content: "\e922";
  color: #aeaeae;
}
.icon-Media_Nav_Metadata:before {
  content: "\e923";
  color: #aeaeae;
}
.icon-Media_Toolbox_Arrow_2:before {
  content: "\e924";
  color: #fff;
}
.icon-Media_Toolbox_Arrow:before {
  content: "\e925";
  color: #fff;
}
.icon-Media_ToolboxArrow:before {
  content: "\e926";
  color: #fff;
}
.icon-Media_ToolboxEllipse:before {
  content: "\e927";
  color: #fff;
}
.icon-Media_ToolboxEraser:before {
  content: "\e928";
  color: #fff;
}
.icon-Media_ToolboxPen:before {
  content: "\e929";
  color: #fff;
}
.icon-Media_ToolboxSquare:before {
  content: "\e92a";
  color: #fff;
}
.icon-Message_Attach:before {
  content: "\e92b";
  color: #e9e9e9;
}
.icon-Message_send:before {
  content: "\e92c";
  color: #e9e9e9;
}
.icon-Message_Tag:before {
  content: "\e92d";
  color: #e9e9e9;
}
.icon-Meteo_Cloud:before {
  content: "\e92e";
  color: #dedede;
}
.icon-Meteo_Sun:before {
  content: "\e92f";
  color: #dedede;
}
.icon-Mobile_1Approve:before {
  content: "\e930";
  color: #25f76a;
}
.icon-Mobile_2Reject:before {
  content: "\e931";
  color: #ff2905;
}
.icon-Nav_:before {
  content: "\e932";
  color: #e9e9e9;
}
.icon-Nav_1:before {
  content: "\e933";
  color: #e9e9e9;
}
.icon-Nav_Menu:before {
  content: "\e934";
  color: #e9e9e9;
}
.icon-Nav_Notifications:before {
  content: "\e935";
  color: #e9e9e9;
}
.icon-Nav_Search:before {
  content: "\e936";
  color: #e9e9e9;
}
.icon-Radio_1Unselected:before {
  content: "\e937";
  color: #e9e9e9;
}
.icon-Radio_2Selected:before {
  content: "\e938";
  color: #e9e9e9;
}
.icon-Radio_3Multiselect:before {
  content: "\e939";
  color: #e9e9e9;
}
.icon-Round_Check:before {
  content: "\e93a";
  color: #fff;
}
.icon-Session_Comment:before {
  content: "\e93b";
  color: #aeaeae;
}
.icon-Hand:before {
  content: "\e93c";
  color: #aeaeae;
}
.icon-Session_Urgency:before {
  content: "\e93d";
  color: #aeaeae;
}
.icon-Session_Validate:before {
  content: "\e93e";
  color: #aeaeae;
}
.icon-Star:before {
  content: "\e93f";
  color: #4c4c4c;
}
.icon-State_2Retouching:before {
  content: "\e941";
  color: #fff;
}
.icon-State_3Waiting:before {
  content: "\e942";
  color: #fff;
}
.icon-State_4Valid:before {
  content: "\e943";
  color: #fff;
}
.icon-Task_Checkbox_1Empty:before {
  content: "\e944";
  color: #365644;
}
.icon-Task_Checkbox_2Done:before {
  content: "\e945";
  color: #25f76a;
}
.icon-Task_Checkbox:before {
  content: "\e946";
  color: #dedede;
}
.icon-Task_Comment:before {
  content: "\e947";
  color: #dedede;
}
.icon-Task_Timer:before {
  content: "\e948";
  color: #dedede;
}
.icon-Upload:before {
  content: "\e949";
  color: #dedede;
}
.icon-View_1BigList:before {
  content: "\e94a";
  color: #e9e9e9;
}
.icon-View_2SmallList:before {
  content: "\e94b";
  color: #e9e9e9;
}
.icon-View_Grid:before {
  content: "\e94c";
  color: #e9e9e9;
}
.icon-View_Info:before {
  content: "\e94d";
  color: #e9e9e9;
}
.icon-visibility:before {
  content: "\e94e";
}
.icon-visiblity_off:before {
  content: "\e94f";
  color: #e9e9e9;
}
