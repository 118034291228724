.img-version-container {
    background-color: #323232;
    width: 16vw;
    height: 124px;
    border-radius: 8px;
    margin-bottom: 1vh;
    cursor: pointer;
}

.version-thumbnail {
    width: auto; 
    height: 108px;
}

.time-icon {
    background-color: #4C4C4C; 
    width: 40px;
    height: 40px;
    border-radius: 4px;
}