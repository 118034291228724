.overview-panel,
.comment-panel {
  height: 92vh;
}
.comment-panel {
  background-color: $secondaryBgColor;
  padding: 5%;
}

.p-overlaypanel {
  &.file-details {
    .p-overlaypanel-content {
      padding: 0 !important;
      border-radius: 10px;
    }
  }
}

.p-button.p-button-text {
  color: #adadad !important;
}

.comment-box {
  padding: 3%;
  background-color: #323232;
  border-radius: 4px;
}

.bottom-panel {
  height: 40px;
  width: 456px;
  border-radius: 4px;
  background-color: #252525;
  position: fixed;
  bottom: 50px;
  z-index: 5;
}

.p-tieredmenu {
  top: 14% !important;
  left: 91% !important;
  width: 6% !important;
}

.file-zoom-panel {
  border-radius: 5px;
  z-index: 5;
  width: 70px;
}

.file-zoom-minus {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #323232;
  text-align: center;
}

.file-zoom-plus {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #323232;
  cursor: pointer;
  text-align: center;
}

.file-zoom-minus:before {
  content: '-';
  font-size: 20px;
  margin: auto;
  line-height: 30px;
  transform: translate(-50%);
}

.file-zoom-plus:before {
  content: '+';
  font-size: 20px;
  margin: auto;
  line-height: 30px;
  transform: translate(-50%);
}

.comment-dialog-button {
  background-color: #E9E9E9;
  width: 5vw !important;
  float: right;
}

.chooseMember-dialog-validateButton {
  width: 71px;
  height: 24px;
  background-color: #E9E9E9!important;
  font-size: 11px;
}

.chooseMember-dialog-cancelButton {
  width: 71px;
  height: 24px;
  background-color: #323232!important;
  color: #E9E9E9 !important;
  font-size: 11px;
  outline: none !important;
}

.avatar-plus{background-color: #252525;}

.overlayPanel-scrollPanel-container {
  width: 10vw; 
  max-height: 40vh; 
  display: flex; 
  flex-flow: row; 
  justify-content: space-between; 
  align-items: center;
}

.member-box {
  font-size: 12px;
  width: 80%;
}

.overlayPanel-footer {
  border-top: 1px solid #464646; 
  padding-top: 1vh;
}

.cursor {cursor: pointer;}

.dark-bg {background-color: #323232;}

.radius4 {border-radius: 4px;}

.greyColor {color: #AEAEAE}

.big-page-size {
  width: 55vw !important;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

