.p-panel {
  border-radius: 7px;
  &.rounded-content {
    .p-panel-content {
      padding: 1.5rem;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;

      background-color: $thirdBgColor;
    }
  }

  &.panel-sm {
    .p-panel-content{
        padding: 0.15rem;
    }
  }

  .p-panel-header {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    justify-content: center;
    background: #282828;
  }
  .p-panel-content {
    padding: 2rem;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    background: #282828;
  }
}
