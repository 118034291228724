.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: $textColor;
    border-radius: 50%;
    z-index: 1;
}
.hover:hover{ 
    color: rgba(255, 255, 255, 0.87);
    background: rgba(255, 255, 255, 0.04);
}