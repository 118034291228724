.p-tabmenu{
    
    .p-tabmenu-nav
    {
        border:none;
        text-transform: uppercase;
        font-size: 0.75rem;

        .p-tabmenu-ink-bar{
            height:1px;
            background-color:$dividerColor
        }
        .p-tabmenuitem{
            &.p-highlight {
                .p-menuitem-link {
                color:$textColor;
                font-weight: bold;
                
            }
        }
            .p-menuitem-link {
                color:$textSecondaryColor;
                background-color: transparent;
                font-weight: $fontWeight;
            }
        }

        .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover {
                .p-menuitem-link {
                    color:$textSecondaryColor;
                    background-color: transparent;
                    font-weight: bold;
                }
            }
        
    }
}
