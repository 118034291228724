.p-tabview {
    margin-left:40px; 
    margin-top:30px;
    .p-tabview-nav 
    { 
        border : none;

        .p-highlight {
            .p-tabview-nav-link {
                color:$textColor;

        };
        .p-tabview-ink-bar{
            background-color:none;
        };

        .p-tabview-nav-link:focus {
            background-color:none
        }
    }
}
}


