.addCampaignButton {
    background-color:#323232;
    border-radius: 4px;
    margin-left: 95%;
}

.new-project-container {
    border: 1px dashed white;
    width: 12vw;
    height: 32vh; 
    border-radius: 10px;
    cursor: pointer;
}

.new-project-button {
    width: 70%; 
    padding: 4%;
    cursor: pointer;
}